.App {

}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: -10px 0 20px;
}

.App-header {
  background-color: #222;
  height: 100px;
  padding: 20px;
  color: white;
  text-align: center;
}

.force-38 {
  height: 38px;
}

.App-title {
  font-size: 1.5em;
  line-height: 60px;
  vertical-align: center;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
